<template>
	<!--持证人员管理-->
	<div class="content-wrap instrument-wrapper">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="姓名">
				<Input v-model="searchParams.username" placeholder="模糊查询"></Input>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction
			v-if="systemActionPermissions.indexOf('btn_certificate_user_add') > -1"
		>
			<Button @click="handleAddShow"> 新增 </Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
			ref="table"
		>
		</CtmsDataGrid>
		<AddUser
			:visible="addVisible"
			:currentRow="currentRow"
			@onCancel="addVisible = false"
			@onOk="initList"
			:id="updateId"
		>
		</AddUser>
	</div>
</template>
<script>
import { mapState } from "vuex"
import api from "@/api/cert/cert"
import AddUser from "./AddUser.vue"

const { getCertUserListPage, delCertUser } = api
export default {
	components: {
		AddUser
	},
	data() {
		return {
			loading: false,
			searchParams: {
				username: ""
			},
			oldSearchParams: {},
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			updateId: "",
			addVisible: false,
			listData: [],
			columns: [
				{
					title: "姓名",
					key: "username",
					minWidth: 120
				},
				{
					title: "职务",
					key: "position",
					minWidth: 120
				},
				{
					title: "职称",
					key: "jobTitle",
					minWidth: 120
				},
				{
					title: "持证数量",
					key: "certificateCount",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: () => {
						const btnList = [
							{
								label: "编辑",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "删除",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认删除这条数据吗？"
								}
							}
						]
						if (
							this.systemActionPermissions.indexOf(
								"btn_certificate_user_update"
							) === -1
						) {
							btnList[0] = null
						}
						if (
							this.systemActionPermissions.indexOf(
								"btn_certificate_user_delete"
							) === -1
						) {
							btnList[1] = null
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			currentRow: null
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 130
			},
			...mapState("permission", ["systemActionPermissions"])
		})
	},
	created() {
		this.initList()
	},
	methods: {
		// 获取数据
		async initList() {
			this.loading = true
			const res = await getCertUserListPage({
				...this.oldSearchParams,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res && res.data) {
				this.listData = res.data.list || []
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				username: ""
			}
			this.searchHandle()
		},
		// 新增
		handleAddShow() {
			this.updateId = ""
			this.currentRow = null
			this.addVisible = true
		},
		// 修改
		handleUpdateShow({ row }) {
			this.updateId = row.id
			this.currentRow = { ...row }
			this.addVisible = true
		},
		// 删除
		async handleSwitchState({ row }) {
			this.loading = true
			const res = await delCertUser(row.id)
			if (res) {
				this.$Message.success("删除成功！")
				this.initList()
			}
			this.loading = false
		},
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>
